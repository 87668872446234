export const en = {
  above: 'Above',
  access: 'Access',
  account: 'Account',
  accountExists: 'Account with provided email already exists',
  active: 'Active',
  administrator: 'Administrator',
  addEntity: 'Add {{entity}}',
  alert: 'Alert',
  alerts: 'Alerts',
  areYouSure: 'Are you sure?',
  average: 'Average',
  backToPage: 'Back to {{page}}',
  below: 'Below',
  betaWaitList: 'Beta Wait List',
  between: 'Between',
  bookmarkAdded: 'Bookmark added',
  bookmarkGage: 'Bookmark gage',
  bookmarkRemoved: 'Bookmark removed',
  bugReport: 'Bug report',
  canada: 'Canada',
  cancel: 'Cancel',
  channel: 'Channel',
  chartMetric: 'Chart metric',
  chile: 'Chile',
  clearingSessionData: 'Clearing session data',
  confirmPassword: 'Confirm password',
  contact: 'Contact',
  country: 'Country',
  countryCodeRequired: 'Country code required',
  criteria: 'Criteria',
  cubicFeetPerSecond: 'Cubic Feet Per Second (CFS)',
  currentlyAvailableForUSGSOnly: 'Currently available for USGS gages only',
  daily: 'Daily',
  dashboard: 'Dashboard',
  date: 'Date',
  dateFormat: 'Date format',
  dayOfTheYear: 'Day of the year',
  delete: 'Delete',
  deleteAccount: 'Delete account',
  description: 'Description',
  developer: 'Developer',
  disclaimer: 'Disclaimer',
  distance: 'Distance',
  email: 'Email',
  editEntity: 'Edit {{entity}}',
  entityCreateError: 'Failed to create {{entity}}',
  entityCreateSuccess: '{{entity}} created',
  entityDeleteError: 'Failed to delete {{entity}}',
  entityDeleted: '{{entity}} deleted',
  entityLoading: '{{entity}} loading',
  failedToAction: 'Failed to {{action}}',
  failedToLoadEntity: 'Failed to load {{entity}}',
  failedToLoadGages: 'Failed to load gages', // ^ use above with plurals
  featureRequest: 'Feature request',
  feature: 'Feature',
  features: 'Features',
  flow: 'Flow',
  flowRanges: 'Flow ranges',
  forecast: 'Forecast',
  forgotPassword: 'Forgot password',
  gage: 'Gage',
  gages: 'Gages',
  gageSiteId: 'Gage Site ID',
  gageName: 'Gage name',
  gageSearchResults: 'Gage search results',
  grade: 'Grade',
  goodBye: 'Goodbye',
  graphGeneratedForGage: 'Graph generated for gage',
  home: 'Home',
  immediate: 'Immediate',
  interval: 'Interval',
  invalidEmail: 'Invalid email',
  lastSent: 'Last sent',
  latestReading: 'Latest reading',
  loading: 'Loading',
  loadingDailyAverages: 'Loading daily averages',
  loadingFlowPredictions: 'Loading flow predictions',
  maximum: 'Maximum',
  maximumNumberOfAlertsCreated: 'Maximum number of alerts already created',
  media: 'Media',
  mediaType: 'Media Type',
  messageSent: 'Message sent',
  sendMessage: 'Send message',
  metric: 'Metric',
  middle: 'Middle',
  minimum: 'Minimum',
  minimumEightCharacters: 'Minimum eight characters',
  missingGage: 'Missing gage',
  misc: 'Miscellaneous',
  mustContainLowercase: 'Must contain lowercase',
  mustContainOneNumber: 'Must contain one number',
  mustContainSpecialCharacter: 'Must contain special character',
  mustContainUppercase: 'Must contain uppercase',
  name: 'Name',
  newZealand: 'New Zealand',
  noData: 'No Data',
  noLocationDataAvailable: 'No location data available',
  ok: 'Ok',
  page: 'Page',
  password: 'Password',
  passwordsDoNotMatch: 'Passwords do not match',
  pleaseConfirmPassword: 'Please confirm password',
  pleaseEnterYourEmailtoConfirm: 'Please enter your email to confirm',
  pleaseInputYourName: 'Please input your name',
  pleaseInputYourPassword: 'Please input your password',
  pleaseVerifyYourAccount: 'Please verify your account',
  pleaseInputYourEmail: 'Please input your email',
  pleaseIncludeTitle: 'Please include title',
  pleaseProvide: 'Please provide {{value}}',
  prophet: 'Prophet',
  province: 'Province',
  readings: 'Readings',
  reach: 'River',
  reaches: 'Rivers',
  register: 'Register',
  removeBookmark: 'Remove bookmark',
  resetPassword: 'Reset password',
  resetSearch: 'Reset search',
  search: 'Search',
  selectTime: 'Select time',
  settingsUpdated: 'Settings updated',
  share: 'Share',
  signIn: 'Sign in',
  signInToBookmarkGage: 'Sign in to bookmark gage',
  signOut: 'Sign out',
  sms: 'SMS',
  somethingWentWrong: 'Something went wrong',
  source: 'Source',
  unauthorized: 'Unauthorized',
  state: 'State',
  stationId: 'Station ID',
  submit: 'Submit',
  subTitle: 'Subtitle',
  summary: 'Summary',
  telephone: 'Telephone',
  telephoneHelpText: 'Country code required',
  telephoneTooltip:
    'Used for SMS alerts that you create. You can add this later.',
  termsAndConditions: 'Terms and Conditions',
  time: 'Time',
  timeZone: 'Timezone',
  title: 'Title',
  unitedStates: 'United States',
  updateSettings: 'Update settings',
  updated: 'Updated',
  value: 'Value',
  verifyingYourAccount: 'Verifying your account',
  failedToVerifyAccount: 'Failed to verify your account. Redirecting.',
  accountVerified: 'Account verified. Redirecting.',
  yay: 'Yay',
  hero: {
    primary:
      'Historical river flow data, forecasting, and custom reporting for rivers in the United States and Canada.',
    colOneTitle: 'River Flow Data',
    colOneText:
      'View historical river water level data sourced from the United States Geological Survey and The Government of Canada.',
    colOneAction: 'Search gages',
    colTwoTitle: 'Forecasting',
    colTwoText:
      "Analyze a river's historical data with machine learning to help you plan your next trip.",
    colTwoAction: 'Consult prophet',
    colThreeTitle: 'Reports',
    colThreeText:
      'Create scheduled and immediate alerts summarizing your saved gages or latest gage reading via email or SMS.',
    colThreeAction: 'Get started',
  },
}
