export const es = {
  above: 'Sobre',
  account: 'Cuenta',
  accountExists: 'La cuenta con el correo electrónico proporcionado ya existe',
  active: 'Activo',
  administrator: 'Administrador',
  addEntity: 'Añadir {{entidad}}',
  alert: 'Alerta',
  alerts: 'Alertas',
  areYouSure: '¿Está seguro?',
  average: 'Promedio',
  backToPage: 'Volver a {{página}}',
  below: 'Abajo',
  betaWaitList: 'Lista de espera beta',
  between: 'Entre',
  bookmarkAdded: 'Marcador agregado',
  bookmarkGage: 'indicador de marcador',
  bookmarkRemoved: 'Marcador eliminado',
  bugReport: 'Informe de error',
  canada: 'Canadá',
  cancel: 'Cancelar',
  channel: 'Canal',
  chartMetric: 'Métrica de gráfico',
  chile: 'Chile',
  clearingSessionData: 'Borrar datos de la sesión',
  confirmPassword: 'Confirmar Contraseña',
  contact: 'Contacto',
  country: 'País',
  countryCodeRequired: 'Código de país requerido',
  criteria: 'Criterios',
  cubicFeetPerSecond: 'Pies cúbicos por segundo (CFS)',
  currentlyAvailableForUSGSOnly:
    'Actualmente disponible solo para calibres USGS',
  daily: 'Diario',
  dashboard: 'Panel',
  date: 'Fecha',
  dateFormat: 'Formato de fecha',
  dayOfTheYear: 'día del año',
  delete: 'Borrar',
  deleteAccount: 'Borrar cuenta',
  description: 'Descripción',
  developer: 'Desarrollador',
  disclaimer: 'Descargo de responsabilidad',
  email: 'Correo electrónico',
  entityCreateError: 'Error al crear {{entidad}}',
  entityCreateSuccess: '{{entidad}} creada',
  entityDeleteError: 'No se pudo eliminar {{entity}}',
  entityDeleted: '{{entidad}} eliminado',
  entityLoading: '{{entidad}} cargando',
  failedToAction: 'Error al {{acción}}',
  failedToLoadEntity: 'Error al cargar {{entidad}}',
  failedToLoadGages: 'Error al cargar los medidores',
  featureRequest: 'Solicitud de función',
  flow: 'Caudal',
  flowRanges: 'Rangos de flujo',
  forecast: 'Pronóstico',
  forgotPassword: 'Se te olvidó tu contraseña',
  gage: 'Calibrar',
  gages: 'Calibres',
  gageSiteId: 'ID del sitio del instrumento',
  gageName: 'Nombre del instrumento',
  gageSearchResults: 'Resultados de la búsqueda de instrumentos',
  goodBye: 'Adiós',
  graphGeneratedForGage: 'Gráfica generada para gage',
  home: 'Casa',
  immediate: 'Inmediato',
  interval: 'Intervalo',
  invalidEmail: 'Email inválido',
  lastSent: 'Último enviado',
  latestReading: 'Última lectura',
  loading: 'Cargando',
  loadingDailyAverages: 'Cargando promedios diarios',
  loadingFlowPredictions: 'Cargando predicciones de flujo',
  maximum: 'Máximo',
  maximumNumberOfAlertsCreated: 'Número máximo de alertas ya creadas',
  messageSent: 'Mensaje enviado',
  sendMessage: 'Enviar mensaje',
  metric: 'Métrico',
  middle: 'Medio',
  minimum: 'Mínimo',
  minimumEightCharacters: 'Mínimo ocho caracteres',
  missingGage: 'calibre faltante',
  misc: 'Misceláneas',
  mustContainLowercase: 'Debe contener minúsculas',
  mustContainOneNumber: 'Debe contener un número',
  mustContainSpecialCharacter: 'Debe contener carácter especial',
  mustContainUppercase: 'Debe contener mayúsculas',
  name: 'Nombre',
  newZealand: 'Nueva Zelanda',
  noLocationDataAvailable: 'No hay datos de ubicación disponibles',
  ok: 'De acuerdo',
  page: 'Página',
  password: 'Clave',
  passwordsDoNotMatch: 'Las contraseñas no coinciden',
  pleaseConfirmPassword: 'Por favor confirme la contraseña',
  pleaseEnterYourEmailtoConfirm:
    'Por favor ingrese su correo electrónico para confirmar',
  pleaseInputYourName: 'Por favor ingrese su nombre',
  pleaseInputYourPassword: 'Por favor ingrese su contraseña',
  pleaseVerifyYourAccount: 'Por favor, verifique su cuenta',
  pleaseInputYourEmail: 'Por favor ingrese su correo electrónico',
  pleaseIncludeTitle: 'Incluya el título',
  pleaseProvide: 'Proporcione {{valor}}',
  prophet: 'Profeta',
  province: 'Provincia',
  readings: 'lecturas',
  reach: 'Rio',
  reaches: 'Rios',
  register: 'Registro',
  removeBookmark: 'Quitar marcador',
  resetSearch: 'Restablecer búsqueda',
  search: 'Búsqueda',
  selectTime: 'Seleccionar hora',
  settingsUpdated: 'Ajustes actualizan',
  share: 'Cuota',
  signIn: 'Registrarse',
  signInToBookmarkGage: 'Regístrese para marcar gage',
  signOut: 'Desconectar',
  sms: 'SMS',
  somethingWentWrong: 'Algo salió mal',
  source: 'Fuente',
  unauthorized: 'No autorizado',
  state: 'Expresar',
  stationId: 'Identificación de la estación',
  submit: 'Entregar',
  telephone: 'Teléfono',
  telephoneHelpText: 'Código de país requerido',
  telephoneTooltip:
    'Se utiliza para las alertas de SMS que crea. Puedes agregar esto más tarde.',
  termsAndConditions: 'Términos y condiciones',
  time: 'Tiempo',
  timeZone: 'Zona horaria',
  title: 'Título',
  unitedStates: 'Estados Unidos',
  updateSettings: 'Ajustes de actualización',
  updated: 'Actualizado',
  value: 'Valor',
  verifyingYourAccount: 'Verificando tu cuenta',
  failedToVerifyAccount: 'No se pudo verificar su cuenta. Redirigir',
  accountVerified: 'Cuenta verificada. Redirigir',
  yay: 'Hurra',
  hero: {
    primary:
      'Datos históricos de flujo de ríos, pronósticos e informes personalizados para ríos en los Estados Unidos y Canadá.',
    colOneTitle: 'Datos de flujo del río',
    colOneText:
      'Vea los datos históricos del nivel del agua del río provenientes del Servicio Geológico de los Estados Unidos y el Gobierno de Canadá.',
    colOneAction: 'Buscar calibres',
    colTwoTitle: 'Pronóstico',
    colTwoText:
      'Analice los datos históricos de un río con el aprendizaje automático para ayudarlo a planificar su próximo viaje.',
    colTwoAction: 'consultar profeta',
    colThreeTitle: 'Informes',
    colThreeText:
      'Cree alertas programadas e inmediatas que resuman sus instrumentos guardados o la última lectura de instrumentos por correo electrónico o SMS.',
    colThreeAction: 'Empezar',
  },
}
