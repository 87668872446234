export const languages = [
  {
    value: 'en',
    text: 'English',
  },
  {
    value: 'es',
    text: 'Spanish',
  },
  {
    value: 'fr',
    text: 'French',
  },
]
