export const fr = {
  above: 'Au dessus de',
  account: 'Compte',
  accountExists: "Le compte avec l'adresse e-mail fournie existe déjà",
  active: 'Actif',
  administrator: 'Administrateur',
  addEntity: 'Ajouter {{entity}}',
  alert: 'Alerte',
  alerts: 'Alertes',
  areYouSure: 'Es-tu sûr?',
  average: 'Moyen',
  backToPage: 'Retour à {{page}}',
  below: 'Au dessous de',
  betaWaitList: "Liste d'attente bêta",
  between: 'Entre',
  bookmarkAdded: 'Marque-page ajouté',
  bookmarkGage: 'Jauge de signet',
  bookmarkRemoved: 'Marque-page supprimé',
  bugReport: "Rapport d'erreur",
  canada: 'Canada',
  cancel: 'Annuler',
  channel: 'Canal',
  chartMetric: 'Graphique métrique',
  chile: 'Chile',
  clearingSessionData: 'Effacement des données de session',
  confirmPassword: 'Confirmez le mot de passe',
  contact: 'Contacter',
  country: 'Pays',
  countryCodeRequired: 'Code pays requis',
  criteria: 'Critère',
  cubicFeetPerSecond: 'Pieds cubes par seconde (CFS)',
  currentlyAvailableForUSGSOnly:
    'Actuellement disponible pour les jauges USGS uniquement',
  daily: 'du quotidien',
  dashboard: 'Tableau de bord',
  date: 'Date',
  dateFormat: 'Format de date',
  dayOfTheYear: "Jour de l'année",
  delete: 'Supprimer',
  deleteAccount: 'Supprimer le compte',
  description: 'La description',
  developer: 'Développeur',
  disclaimer: 'Avertissement',
  email: 'E-mail',
  entityCreateError: 'Échec de la création de {{entité}}',
  entityCreateSuccess: '{{entité}} créée',
  entityDeleteError: 'Échec de la suppression de {{entity}}',
  entityDeleted: '{{entity}} supprimée',
  entityLoading: 'chargement de {{entity}}',
  failedToAction: 'Échec de {{action}}',
  failedToLoadEntity: 'Échec du chargement de {{entity}}',
  failedToLoadGages: 'Échec du chargement des jauges',
  featureRequest: 'Demande de fonctionnalité',
  flow: 'Flux',
  flowRanges: 'Plages de débit',
  forecast: 'Prévoir',
  forgotPassword: 'Mot de passe oublié',
  gage: 'Jauge',
  gages: 'Jauges',
  gageSiteId: 'ID de site Gage',
  gageName: 'Nom de la jauge',
  gageSearchResults: 'Résultats de la recherche de jauge',
  goodBye: 'Au revoir',
  graphGeneratedForGage: 'Graphique généré pour la jauge',
  home: 'Domicile',
  immediate: 'Immédiat',
  interval: 'Intervalle',
  invalidEmail: 'Email invalide',
  lastSent: 'Dernier envoi',
  latestReading: 'Dernière lecture',
  loading: 'Chargement',
  loadingDailyAverages: 'Chargement des moyennes journalières',
  loadingFlowPredictions: 'Chargement des prédictions de flux',
  maximum: 'Maximum',
  maximumNumberOfAlertsCreated: "Nombre maximum d'alertes déjà créées",
  messageSent: 'Message envoyé',
  sendMessage: 'Envoyer le message',
  metric: 'Métrique',
  middle: 'Milieu',
  minimum: 'Le minimum',
  minimumEightCharacters: 'Minimum huit caractères',
  missingGage: 'Jauge manquante',
  misc: 'Divers',
  mustContainLowercase: 'Doit contenir des minuscules',
  mustContainOneNumber: 'Doit contenir un chiffre',
  mustContainSpecialCharacter: 'Doit contenir un caractère spécial',
  mustContainUppercase: 'Doit contenir des majuscules',
  name: 'Nom',
  newZealand: 'Nouvelle-Zélande',
  noLocationDataAvailable: 'Aucune donnée de localisation disponible',
  ok: "D'accord",
  page: 'Page',
  password: 'Mot de passe',
  passwordsDoNotMatch: 'Les mots de passe ne correspondent pas',
  pleaseConfirmPassword: 'Veuillez confirmer le mot de passe',
  pleaseEnterYourEmailtoConfirm: 'Veuillez entrer votre email pour confirmer',
  pleaseInputYourName: 'Veuillez entrer votre nom',
  pleaseInputYourPassword: 'Veuillez entrer votre mot de passe',
  pleaseVerifyYourAccount: 'Veuillez vérifier votre compte',
  pleaseInputYourEmail: 'Veuillez saisir votre email',
  pleaseIncludeTitle: 'Veuillez inclure le titre',
  pleaseProvide: 'Veuillez fournir {{value}}',
  prophet: 'Prophète',
  province: 'Province',
  readings: 'Lectures',
  register: "S'inscrire",
  removeBookmark: 'Supprimer le signet',
  resetSearch: 'Réinitialiser la recherche',
  search: 'Recherche',
  selectTime: "Sélectionnez l'heure",
  settingsUpdated: 'Paramètres mis à jour',
  share: 'Partager',
  signIn: "S'identifier",
  signInToBookmarkGage: 'Connectez-vous à la jauge de favoris',
  signOut: 'Déconnexion',
  sms: 'SMS',
  somethingWentWrong: "Quelque chose s'est mal passé",
  source: 'La source',
  unauthorized: 'Non autorisé',
  state: 'État',
  stationId: 'Identifiant de la station',
  submit: 'Soumettre',
  telephone: 'Téléphone',
  telephoneHelpText: 'Code pays requis',
  telephoneTooltip:
    "Utilisé pour les alertes SMS que vous créez. Vous pouvez l'ajouter plus tard.",
  termsAndConditions: 'Termes et conditions',
  time: 'Temps',
  timeZone: 'Fuseau horaire',
  title: 'Titre',
  unitedStates: 'États-Unis',
  updateSettings: 'Mettre à jour les paramètres',
  updated: 'Mis à jour',
  value: 'Valeur',
  verifyingYourAccount: 'Vérification de votre compte',
  failedToVerifyAccount:
    'Échec de la vérification de votre compte. Redirection.',
  accountVerified: 'Compte vérifié. Redirection.',
  yay: 'Yay',
  hero: {
    primary:
      'Données historiques sur le débit des rivières, prévisions et rapports personnalisés pour les rivières aux États-Unis et au Canada.',
    colOneTitle: 'Données sur le débit de la rivière',
    colOneText:
      "Consultez les données historiques sur le niveau d'eau des rivières provenant de la Commission géologique des États-Unis et du gouvernement du Canada.",
    colOneAction: 'Jauges de recherche',
    colTwoTitle: 'Previsión',
    colTwoText:
      "Analysez les données historiques d'une rivière avec l'apprentissage automatique pour vous aider à planifier votre prochain voyage.",
    colTwoAction: 'Consulter le prophète',
    colThreeTitle: 'Rapports',
    colThreeText:
      'Créez des alertes programmées et immédiates résumant vos jauges enregistrées ou la dernière lecture de jauge par e-mail ou SMS.',
    colThreeAction: 'Commencer',
  },
}
